import { ComponentProps } from "preact";
import { useRef } from "preact/hooks";
import { formatNumber, shortenNumber } from "../../../common/helper";
import { useActivate } from "../../../common/useActivate";

export function ShortNumber({
  className,
  number,
  ...props
}: ComponentProps<"span"> & { number: number }) {
  const ref = useRef<HTMLSpanElement>(null);
  useActivate(ref);

  return (
    <span
      ref={ref}
      class={className}
      title={formatNumber(number)}
      data-xf-init="tooltip"
      {...props}
    >
      {shortenNumber(number)}
    </span>
  );
}
