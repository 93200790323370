export const strings = {
  LAST_REPLIED: "Last Replied:",
  POSTED: "Posted:",
  SENSITIVE_CONTENT: "Sensitive Content",
  LOAD_MORE: "Load More",
  REPLIED: "replied",
  OUR_TOP_FORUMS: "Our Top Forums",
  VIEW_ALL: "View All",
  ARTICLE: "Article",
};
