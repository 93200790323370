import clsx from "clsx";
import { ComponentProps } from "preact";
import { useMemo, useRef } from "preact/hooks";
import {
  convertUTCSecondsToDate,
  convertUTCSecondsToDateTime,
  convertUTCSecondsToRelative,
  convertUTCSecondsToTime,
} from "../../../common/helper";
import { useActivate } from "../../../common/useActivate";

export function RelativeDate({
  className,
  date,
  ...props
}: ComponentProps<"time"> & { date: number }) {
  const dateObj = useMemo(() => new Date(date * 1000), [date]);

  const ref = useRef<HTMLTimeElement>(null);
  useActivate(ref);

  return (
    <time
      ref={ref}
      class={clsx(className, "u-dt")}
      dir="auto"
      dateTime={dateObj.toISOString()}
      // eslint-disable-next-line react/no-unknown-property
      california-data-time={date}
      data-date-string={convertUTCSecondsToDate(date)}
      data-time-string={convertUTCSecondsToTime(date)}
      title={convertUTCSecondsToDateTime(date)}
      data-xf-init="tooltip"
      {...props}
    >
      {convertUTCSecondsToRelative(date)}
    </time>
  );
}
