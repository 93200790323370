import { strings } from "../../homepage-strings";
import { Avatar } from "../Avatar/Avatar";
import { RelativeDate } from "../RelativeDate/RelativeDate";
import { ShortNumber } from "../ShortNumber/ShortNumber";
import { Thumbnail } from "../Thumbnail/Thumbnail";
import { Username } from "../Username/Username";

export function FeedItem({ index, threadInfo, enableCF }) {
  const {
    title,
    link,
    forum,
    user,
    /* eslint-disable @typescript-eslint/naming-convention */
    last_poster,
    reply_count,
    post_date,
    last_post,
    is_unread,
    first_post,
    thread_prefix,
    is_article,
    following_status,
    is_listing,
    listing,
    is_deal,
    /* eslint-enable @typescript-eslint/naming-convention */
  } = threadInfo;

  return (
    <div class="feedItem-container" qid="thread-item">
      <Avatar mobileHidden user={user} />
      <div class={`feedItem-content ${is_unread ? "is-unread" : "is-read"}`}>
        <div class="feedItem-thread-information">
          <div class="feedItem-forum-title">
            {is_article && (
              <>
                <a
                  class="feedItem-forum-article-wrapper"
                  qid="article-identifier"
                  href={user.profile_link}
                >
                  <div class="feedItem-forum-article-icon" />
                  <span class="feedItem-forum-article">{strings.ARTICLE}</span>
                </a>
                <span class="feedItem-forum-separator">&middot;</span>
              </>
            )}
            <a
              class="feedItem-forum-name--gtm"
              href={forum.link}
              qid="forum-title"
            >
              {forum.title}
            </a>
          </div>
          <div
            class={`feedItem-thread-title ${
              is_unread ? "feedItem-unread" : "feedItem-read"
            }`}
          >
            {thread_prefix.title && (
              <span
                class={thread_prefix.css_class.replace(
                  /listing-type-/,
                  "label--listing label--"
                )}
              >
                {thread_prefix.title}
              </span>
            )}
            <a href={link} class="thread-title--gtm" qid="thread-title">
              {title}
            </a>
          </div>
          <div class="feedItem-thread-details" qid="thread-details">
            <div class="feedItem-thread-details-user">
              <span class="feedItem-thread-details-posted">
                <Username
                  class="feedItem-thread-details-profile-link feedItem-link-no-theme thread-details-profile-link--gtm"
                  username={user.username}
                  userid={user.user_id}
                  href={user.profile_link}
                  qid="posted-by-username"
                />
                <span class="feedItem-forum-separator">&middot;</span>
                <a href={link} class="feedItem-link-no-theme" qid="posted-time">
                  <RelativeDate class="thread-time--gtm" date={post_date} />
                </a>
              </span>
              {reply_count > 0 && (
                <>
                  <span class="feedItem-forum-separator feedItem-mobile-hidden">
                    &middot;
                  </span>
                  <span class="feedItem-thread-details-replied">
                    <Username
                      class="feedItem-link-no-theme thread-replied-by-user--gtm"
                      username={last_poster.username}
                      userid={last_poster.user_id}
                      href={last_poster.profile_link}
                      qid="replied-by-username"
                    />
                    {` ${strings.REPLIED} `}
                    <a
                      href={last_post.link}
                      class="feedItem-link-no-theme thread-replied-time--gtm"
                      qid="replied-time"
                    >
                      <RelativeDate date={last_post.post_date} />
                    </a>
                  </span>
                </>
              )}
            </div>
            {is_listing && listing.location && (
              <span class="listing-city">
                <i class="fas fa-map-marker-alt" />
                &nbsp;{listing.location}
              </span>
            )}
            <div class="feedItem-following-and-reply-wrapper">
              {following_status.text && following_status.link && (
                <a
                  class="label-following-status thread-following-status--gtm"
                  qid="following-tag"
                  href={following_status.link}
                >
                  {following_status.text}
                </a>
              )}
              <a class="feedItem-thread-replies" href={last_post.link}>
                <div
                  class="feedItem-reply-icon"
                  aria-label={`${reply_count} comments`}
                />
                <ShortNumber
                  id={`reply-count-${index}`}
                  qid="feedItem-replies-count"
                  number={reply_count}
                />
              </a>
            </div>
          </div>
        </div>
        {is_listing && (
          <div class="listing-price">{listing.formatted_price}</div>
        )}
        <Thumbnail
          thumbnail={first_post.thumbnail}
          imageLink={first_post.link}
          threadTitle={title}
          enableCF={enableCF}
          isListing={is_listing}
          isDeal={is_deal}
        />
      </div>
    </div>
  );
}
