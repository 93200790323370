/**
 * @returns object KVs as CSVs
 *
 * Note: does not escape any values
 */
export function toGtmContext(
  obj: Record<string, string | number | undefined | null>
) {
  return Object.entries(obj)
    .map(([key, value]) => `${key}:${value}`)
    .join(",");
}
