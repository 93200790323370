import { RefObject } from "preact";
import { useEffect } from "preact/hooks";
import { XF } from "../XF";

/** @see XF.activate */
export function useActivate<T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>
) {
  useEffect(() => {
    if (!ref.current) return;
    XF.activate(ref.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
