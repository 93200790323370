export function AdItem({
  desktopId,
  mobileId,
}: {
  desktopId: string;
  mobileId: string;
}) {
  return (
    <>
      {desktopId && (
        <div class="california-banner-ad-container california-ad hide-mw-responsiveWide california-ad-in-content-desktop">
          <div id={desktopId} />
        </div>
      )}
      {mobileId && (
        <div class="california-banner-ad california-ad-mobile california-ad feed-california-ad california-ad-in-content-mobile">
          <div id={mobileId} />
        </div>
      )}
    </>
  );
}
