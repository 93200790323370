import clsx from "clsx";
import { ComponentProps } from "preact";
import { useRef } from "preact/hooks";
import { gtmUsername } from "../../../common/gtm-ids";
import { toSlug } from "../../../common/toSlug";
import { useActivate } from "../../../common/useActivate";

export function Username({
  username,
  userid,
  href,
  ...props
}: ComponentProps<"span" | "a"> & {
  username?: string;
  userid?: number;
  href?: string;
}) {
  const ref = useRef<HTMLSpanElement | HTMLAnchorElement>(null);
  useActivate(ref);
  const link =
    href ||
    (userid && `/members/${username ? `${toSlug(username)}.` : ""}${userid}`);
  const Tag = link ? "a" : "span";
  return (
    <Tag
      {...props}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ref={ref}
      href={link || undefined}
      class={clsx(props.class, "username")}
      data-user-id={userid}
      data-xf-init={link && userid ? "member-tooltip" : undefined}
      data-gtm={gtmUsername}
      data-gtm-context={userid}
    >
      {username || "Guest"}
    </Tag>
  );
}
