import { Feed } from "../Feed/Feed";

export function Home({
  useHtmlFeed,
  showAds,
}: {
  useHtmlFeed?: boolean;
  showAds: boolean;
}) {
  return <Feed useHtmlFeed={useHtmlFeed} showAds={showAds} />;
}
